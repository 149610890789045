.container {
  /* DO NOT PUT PADDING HERE! */
  position: fixed;
  right: 30px;
  bottom: 0;
  z-index: 99999;
  width: 98%;
  margin: 0 auto;
}
.snackbar {
  border: 0;
  background-color: rgba(30, 30, 30, 0.85);
  color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  height: 0;
  margin-left: 20px;
  transform: translateY(200%);
}

.snackbar.snackbarOpened {
  height: initial;
  min-height: 46px;
  transform: none;
}
.snackbar {
  overflow: hidden;
  min-width: fit-content;
  width: fit-content;
  max-width: 620px;
  cursor: pointer;
  opacity: 0;
  font-weight: 700;
}
.snackbar a {
  color: #40a4ff;
}
.snackbar a:focus,
.snackbar a:hover {
  color: #3280ca;
}
.snackbar.snackbarOpened {
  height: initial;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.eventIcon {
  width: 46px;
  min-width: 46px;
  background: #40a4ff;
  display: flex;
  align-items: center;
  align-self: stretch;
  justify-content: center;
}
.eventIcon img {
  display: block;
  width: 50%;
}
.succeed {
  color: #4dd8d0;
}
.failure {
  color: #ff6e75;
}
.warning {
  color: #e04177;
}
.succeed .eventIcon {
  background: #4dd8d0;
}
.failure .eventIcon {
  background: #ff6e75;
}
.warning .eventIcon {
  background: #e04177;
}
.snackbarContent {
  padding: 5px 12px;
  display: flex;
  align-items: center;
}
.snackbarContent span {
  font-size: 12px;
  font-weight: 700;
  color: #ddd;
}
.title {
  font-size: 16px;
  margin-right: 6px;
}
@media (max-width: 767px) {
  .container {
    left: 0px !important;
    right: 0px;
    width: 100%;
  }
  .container .snackbar {
    min-width: 100%;
  }
  .container [class="snackbar snackbarOpened"] ~ .snackbar.toast {
    margin-top: 20px;
  }
  .container [class="snackbar snackbarOpened"] {
    margin-bottom: 0;
  }
}
