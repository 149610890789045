  .scaleBox {
    position: relative;
    height: 60px;
    align-items: center;
    display: flex;
    justify-content: space-evenly;
    min-width: 50px;
    bottom: 50%;
  }
  .scaleBoxSmall {
    position: relative;
    align-items: center;
    display: flex;
    justify-content: space-evenly;
    height: 30px;
    min-width: 30px;
    bottom: 50%;
  }
  .scaleLine {
    width: 90%;
    bottom: 50%;
    position: absolute;
  }
  .scaleBoxBody {
    position: absolute;
    width: 60%;
    height: 40%;
    bottom: 30%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 0;
    /* opacity: 0.5; */
    /* background: linear-gradient(to left, #f3f3f3, #79357e, #f3f3f3 ); */
  }
  .gradientReverse {
    background: linear-gradient(to left, #f3f3f3, #e98434, #f3f3f3);
  }
  
  .scaleBoxMarker {
    position: absolute;
    bottom: 50%;
    display: block;
    height: 40%;
  }
  
  /* .tableHint {
    background: #302f67;
    display: flex;
    color: #f3f3f3;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    opacity: 0.6;
    border: 1px solid #302f67;
  }
  .tableHint:disabled {
    background: rgba(255, 255, 255, 0.35);
  }
  .tableHint:hover {
    cursor: pointer;
  } */
  /* .tableHintIcon {
    color: #f3f3f3;
    font-size: 0.9rem;
    font-weight: 600;
    margin:10px;
  } */
  /* .tableHint:hover ~ .hintTooltip {
    display: block;
  } */
  
  .taxInfoDiv {
    /* position: absolute; */
    background: #302f67;
    padding: 10px;
    color: #f3f3f3;
    /* width: 60%; */
    min-height: 300px;
    min-width: 300px;
    max-width: 800px;
  }
  
  .explainDiv {
    position: relative;
    background: #f3f3f3;
    width: 100%;
    padding: 10px 0 0 0;  
    min-width: 400px;
    max-width: 1200px;
    border: 3px solid #b1235c;
  }
  .taxInfoText {
    padding: 10px 0;
    color: #f3f3f3;
  }  

.tabLabel {
    font-weight: bold;
}
.tabLabel:active {
    font-weight: bolder;
}

.tabLabel:enabled {
  /* border: 1px solid #302f67; */
  background: rgba(0, 0, 0, 0.1);  
  /* padding: 4px; */
  color: #302f67;
  font-weight: bold;
}

.tabLabel:hover {
  /* background-color: rgba(255, 255, 255, 0.35); */
  /* opacity: 0.4; */
  border: 2px solid #3f51b5;  
  font-weight: bolder;
  border-radius: 4px;
  color: #3f51b5;
}

.boxDiv {
  /* border:1px solid #b1235c; */
  padding: 10px;
}

.tabPanel {
  /* background: #302f67; */
  padding: 4px;
  width: 100%;
  background: transparent;
}

.infoButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  opacity: 0.8;
  border: 1px solid #302f67;
  background-color: #302f67;
}


.nameTag {
  display: inline-flex;
  align-items: center;
}