.fs-10 {
  font-size: 10px;
}
.fs-12 {
  font-size: 12px;
}
.fs-14 {
  font-size: 14px;
}
.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px;
}
.fs-20 {
  font-size: 20px;
}
.fs-24 {
  font-size: 24px;
}
.fs-44 {
  font-size: 44px;
}
.fs-1\.65rem {
  font-size: 1.65rem;
}

.fs-1\.3rem {
  font-size: 1.3rem;
}
.fs-0\.7rem {
  font-size: 0.7rem;
}
.fs-0\.8rem {
  font-size: 0.8rem;
}
.fs-0\.9rem {
  font-size: 0.9rem;
}
.fs-1rem {
  font-size: 1rem;
}
.fs-1\.1rem {
  font-size: 1.1rem;
}
.fs-1\.3rem {
  font-size: 1.3rem;
}
.fw-500 {
  font-weight: 500;
}
.fw-700 {
  font-weight: 700;
}
.fw-900 {
  font-weight: 900;
}
.italic {
  font-style: italic;
}
