/* @import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=block");*/

/* latin-ext */
/*
@font-face {
  font-family: "Rubik";
  font-style: italic;
  font-weight: 300;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/rubik/v14/iJWEBXyIfDnIV7nEnXC61E_c5IhGzg.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Rubik";
  font-style: italic;
  font-weight: 400;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/rubik/v14/iJWEBXyIfDnIV7nEnXC61E_c5IhGzg.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Rubik";
  font-style: italic;
  font-weight: 500;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/rubik/v14/iJWEBXyIfDnIV7nEnXC61E_c5IhGzg.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Rubik";
  font-style: italic;
  font-weight: 600;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/rubik/v14/iJWEBXyIfDnIV7nEnXC61E_c5IhGzg.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Rubik";
  font-style: italic;
  font-weight: 700;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/rubik/v14/iJWEBXyIfDnIV7nEnXC61E_c5IhGzg.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Rubik";
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v14/iJWEBXyIfDnIV7nEnXC61E_c5IhGzg.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Rubik";
  font-style: italic;
  font-weight: 900;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/rubik/v14/iJWEBXyIfDnIV7nEnXC61E_c5IhGzg.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/rubik/v14/iJWKBXyIfDnIV7nPrXyw023e1Ik.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/rubik/v14/iJWKBXyIfDnIV7nPrXyw023e1Ik.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/rubik/v14/iJWKBXyIfDnIV7nPrXyw023e1Ik.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/rubik/v14/iJWKBXyIfDnIV7nPrXyw023e1Ik.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/rubik/v14/iJWKBXyIfDnIV7nPrXyw023e1Ik.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 800;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/rubik/v14/iJWKBXyIfDnIV7nPrXyw023e1Ik.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/rubik/v14/iJWKBXyIfDnIV7nPrXyw023e1Ik.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
*/
 /* latin 
@font-face {
  font-family: "Rubik";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v14/iJWEBXyIfDnIV7nEnX661E_c5Ig.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Rubik";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v14/iJWEBXyIfDnIV7nEnX661E_c5Ig.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Rubik";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v14/iJWEBXyIfDnIV7nEnX661E_c5Ig.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Rubik";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v14/iJWEBXyIfDnIV7nEnX661E_c5Ig.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Rubik";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v14/iJWEBXyIfDnIV7nEnX661E_c5Ig.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Rubik";
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v14/iJWEBXyIfDnIV7nEnX661E_c5Ig.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Rubik";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v14/iJWEBXyIfDnIV7nEnX661E_c5Ig.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v14/iJWKBXyIfDnIV7nBrXyw023e.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v14/iJWKBXyIfDnIV7nBrXyw023e.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v14/iJWKBXyIfDnIV7nBrXyw023e.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v14/iJWKBXyIfDnIV7nBrXyw023e.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v14/iJWKBXyIfDnIV7nBrXyw023e.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v14/iJWKBXyIfDnIV7nBrXyw023e.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v14/iJWKBXyIfDnIV7nBrXyw023e.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
} */
.body {
  position: relative;
  /* font-family: "DejaVu Sans", "Arial", sans-serif !important;*/
  font-family: "Rubik",  "Arial", sans-serif !important; 
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4;
  box-sizing: border-box;
  margin: 0;
  box-shadow: 0;
  break-after: always !important;
}

@font-face {
  font-family: "Rubik";
  src: url(../../assets/fonts/Rubik/static/Rubik-Regular.ttf)
    format("truetype");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
      U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Rubik";
  font-style: italic;
  src: url(../../assets/fonts/Rubik/static/Rubik-Italic.ttf)
    format("truetype");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
      U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Rubik";
  font-weight: bold;
  src: url(../../assets/fonts/Rubik/static/Rubik-Bold.ttf)
    format("truetype");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
      U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Rubik";
  font-weight: bold;
  font-style: italic;
  src: url(../../assets/fonts/Rubik/static/Rubik-BoldItalic.ttf)
    format("truetype");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
      U+2C60-2C7F, U+A720-A7FF;
}

/*
    The example loads the DejaVu Sans from the Kendo UI CDN.
    Other fonts have to be hosted from your application.
    The official site of the Deja Vu Fonts project is
    https://dejavu-fonts.github.io/.
*/
/*
@font-face {
  font-family: "DejaVu Sans";
  src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans.ttf")
    format("truetype");
}

@font-face {
  font-family: "DejaVu Sans";
  font-weight: bold;
  src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Bold.ttf")
    format("truetype");
}

@font-face {
  font-family: "DejaVu Sans";
  font-style: italic;
  src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf")
    format("truetype");
}

@font-face {
  font-family: "DejaVu Sans";
  font-weight: bold;
  font-style: italic;
  src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf")
    format("truetype");
} */
.singlePage {
  position: relative;
  height: calc(8.7in * 1.33);
  max-height: calc(8.7in * 1.33);
  min-height: calc(8.7in * 1.33);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  break-after: always !important;
  break-inside: avoid !important;
  color: #333;
  /* color: #252351; */
  padding: 0 0.3cm;
}
.pageHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.footer {
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
  font-size: 11px;
}
.footer .stripe {
  height: 30px;
  min-height: 30px !important;
  background: #252351;
  margin-top: 4px;
}
.footerText {
  padding: 10px 0.5cm 0 0.5cm;
}
.content {
  padding: 10px 0.5cm 0.5cm 0.5cm;
}
.textBox,
.textBox p {
  font-size: 12px;
  text-align: justify;
}
.title {
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  color: #252351;
  text-transform: uppercase;
  word-spacing: 6px;
}
h5 {
  font-size: 15px;
  margin-bottom: 2px;
}
h5 ~ div em {
  font-size: 17px;
}
.logo img {
  display: block;
  height: 36px;
}
.logoRight img {
  display: block;
  height: 100px;
}
.result {
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  flex-wrap: nowrap;
}
.resultbmi {
  font-weight: 400;
  font-size: 13px;
  text-align: center;
  flex-wrap: nowrap;
  float: right;
}
.species {
  font-weight: 400;
  font-size: 1rem;
  text-align: right;
  white-space: nowrap;
  float: left;
  border: 2px solid #e98434;
  color: #252351;
  padding: 5px;
  margin-right: 10px;
}
.species strong {
  font-size: 28px;
  font-weight: 600;
}
.speciesdesc {
  font-weight: 400;
  font-size: 0.5rem;
  text-align: right;
  white-space: nowrap;
  float: right;
  border: 2px solid #e98434;
  color: #252351;
  padding: 5px;
}

.speciesdiv {
  float: right;
  display :block;
  align-items: right;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 0px;
}
.dominancediv {
  margin-right: 50px;
  margin-left: 50px;
  margin-top: auto;
}
.moveright {
  overflow: auto;  
}

.result strong {
  font-size: 28px;
  font-weight: 400;
  line-height: 1;
}

.iconLabel {
  font-size: 6px !important;
  text-align: center;
  width: 60px;
  max-width: 70px;
  padding-top: 4px;
}
.textBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-bottom: 0.2cm;
}
.textBox img {
  display: block;
}
.textBoxHeader {
  font-size: 12px;
  max-width: 140px;
}
.infographic img {
  display: block;
}
.scaleChartBox {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 48px;
}
.textBox .barChartMarker {
  position: absolute;
  top: 2px;
  display: block;
  height: 13px;
  width: 10px;
}
.smallTitle,
.subTitle {
  font-size: 14px;
}
.boldTitle {
  font-weight: 800 !important;
  font-size: 15px;
}
.italicTitle {
  font-style:italic;
  font-size: 15px;
}

.smallTitle {
  font-weight: 800 !important;
}
.barChartLabel {
  font-size: 10px;
  font-weight: 500;
  text-align: right;
  margin-top: 2px;
}

.reportBox {
  min-height: calc(100vh - 100px - 14px);
  background: #f3f3f3;
  color: #222;
  border-radius: 0 4px 4px 4px;
  padding-top: 8px;
  padding-bottom: 14px;
  overflow: auto;
}
.reportBox h5 {
  font-size: 19px;
}
.reportBox h6 {
  text-transform: uppercase;
}
.hintBox {
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.textBox {
  width: 100%;
}
.textBoxSm,
.chartBoxSm {
  max-width: 400px;
}
.textBoxMd,
.chartBoxMd {
  width: 65%;
}

.textBoxLg,
.chartBoxLg {
  width: 100%;
}
.textBox,
.chart {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
}
.textBox h3 {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 18px;
}
.textBox h6 {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 14px;
}
.intro {
  font-weight: 600;
}
.enterotype {
  font-size: 32px;
  font-weight: 800;
  text-transform: uppercase;
  margin-top: 14px;
}
.infographic img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}
.resultLabel {
  white-space: nowrap;
  font-size: 10px;
}
.result {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  text-align: center;
  white-space: nowrap;
  padding-left: 10px;
}
.result strong {
  font-size: 26px;
  font-weight: 400;
}
.halfDonutChartBox {
  position: relative;
  width: 320px;
  margin: 0 auto;
  overflow: hidden;
}
.halfDonutChart {
  margin: 0 auto;
  padding: 0;
  list-style-type: none;
}

.halfDonutChart * {
  box-sizing: border-box;
}
/* halfDonutChart */

.halfDonutChart {
  position: relative;
  width: 250px;
  height: 125px;
  margin-top: 26px;
}
.halfDonutChartLabelsContainer {
  width: 300px;
  margin: 0 auto;
  align-self: center;
  padding-top: 5px;
}
.halfDonutChart div {
  position: absolute;
  top: 100%;
  left: 0;
  width: inherit;
  height: inherit;
  border: 15px solid;
  border-top: none;
  border-bottom-left-radius: 150px;
  border-bottom-right-radius: 150px;
  transform-origin: 50% 0;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  animation-fill-mode: forwards;
  /* animation-duration: 0.4s; */
  animation-timing-function: linear;
}

.halfDonutChart div:nth-child(1) {
  z-index: 5;
  border-color: #c7c7c7;
}

.halfDonutChart div:nth-child(2) {
  z-index: 4;
  border-color: #e98433;
  /* animation-delay: 0.4s; */
}

.halfDonutChart div:nth-child(3) {
  z-index: 3;
  border-color: #8bc34a;
  /* animation-delay: 0.8s; */
}

.halfDonutChart div:nth-child(4) {
  z-index: 2;
  border-color: #e98433;
  /* animation-delay: 1.2s; */
}
.halfDonutChart div:nth-child(5) {
  z-index: 1;
  border-color: #c7c7c7;
  /* animation-delay: 1.2s; */
}

.halfDonutChart span {
  position: absolute;
  font-size: 12px;
  font-weight: 600;
  backface-visibility: hidden;
  /* animation: fade-in 0.4s linear forwards; */
}

.halfDonutChart div:nth-child(2) span {
  top: -8px;
  left: 10px;
  transform: rotate(-5deg);
  /* animation-delay: 0.4s; */
}

.halfDonutChart div:nth-child(4) span {
  top: -6px;
  left: 10px;
  transform: rotate(-175deg);
  /* animation-delay: 1.2s; */
}
.chartLabel {
  font-size: 16px;
  font-weight: 400;
  width: 60px;
  margin-right: 5px;
  text-align: center;
}
.halfDonutChartLegend {
  position: absolute;
  left: 50%;
  bottom: -35px;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.halfDonutChartLegend strong,
.halfDonutChartLegend span {
  font-weight: bold;
  color: #252351;
  text-align: center;
}

.halfDonutChartLegend span {
  font-size: 10px;
}

.halfDonutChartLegend strong {
  font-size: 36px;
  font-weight: 500;
}

.halfDonutChartBox .marker {
  animation-name: rotate-six;
  z-index: 6;
  background: transparent;
  border: transparent;
  top: 100%;
}

.chartArea {
  position: relative;
  width: 350px;
  margin: 0 auto;
}

/* 

.halfDonutChartBox .marker:before {
  content: "";
  background: url(../../assets/images/marker.svg) no-repeat;
  position: absolute;
  top: -10px;
  left: -18px;
  width: 15px;
  height: 21px;
  transform: rotate(-90deg);
} */

.halfDonutChartBox .donutmarkerImg {
  position: absolute;
  top: -10px;
  left: -18px;
  width: 15px;
  height: 21px;
  transform: rotate(-90deg);
}

.pieChartContainer {
  position: relative;
  height: 300px;
  width: 300px;
  margin-left: 15px;
}

.entry {
  display: flex;
  align-items: center;
  margin: 2px 0;
}

.entryColor {
  height: 8px;
  width: 8px;
  border-radius: 8px;
}

.entryText {
  margin-left: 5px;
}

.red {
  background-color: #d3342e;
}

.orange {
  background-color: #e98434;
}

.yellow {
  background-color: #facf66;
}

.pink {
  background-color: #b1235c;
}

.black {
  background-color: #3c396b;
}

.purple {
  background-color: #79357e;
}
.scaleChartBox,
.barChartBox {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.barChartBox {
  position: relative;
  padding-top: 4px;
}
.barChartBox {
  margin: 0 auto;
  width: calc(100% - 50px);
}
.barChartBar {
  position: relative;
  width: 100%;
  height: 35px;
  margin: 0 auto;
}
.barChartLabelsBox {
  width: calc(100% + 50px);
  margin: 0 -25px;
}
.barChartLabel {
  width: 40px;
}
.barChartLabelLeft {
  text-align: right;
}
.barChartLabelRight {
  text-align: left;
}
.wave {
  display: block;
  width: 100%;
  height: 100%;
}

.barChartLabel {
  font-weight: 500;
  margin-top: 2px;
}
.barChartMarker {
  position: absolute;
  bottom: -8px;
  display: block;
  height: 19px;
  width: 13px;
  transform: rotate(180deg);
}
.scaleChartMarker {
  position: absolute;
  top: 4px;
  display: block;
  height: 19px;
  width: 13px;
}
.scaleChartBar {
  position: relative;
  width: 100%;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 10px;
  background: linear-gradient(to right, #009688, #ffeb3b, #f44336);
}
.gradientActivity {
  background: linear-gradient(to left, #009688 40%, #ffeb3b, #f44336);
}
.gradientBMI {
  background: linear-gradient(to right, #ffeb3b, #009688, #ffeb3b, #f44336);
}
.gradientAlcohol {
  background: linear-gradient(90deg, #009688, #ffeb3b, #ffe03b, #f44336, #f44336, #f44336);
}
.gradientUnhealthy {
  background: linear-gradient(90deg, #009688, #ffeb3b, #f44336 50% );
}
.gradientRedGreen {
  background: linear-gradient(90deg, #f44336, #ffeb3b, #009688 );
}

.scaleChartLabel {
  font-size: 9px;
  font-weight: 600;
}
.divider {
  width: 2px;
  height: 10px;
  background: #fff;
}
.infographic img {
  display: block;
  margin: 10px auto;
  width: 280px;
}
.pieChart {
  border-radius: 50%;
  width: 300px;
  height: 300px;
  margin: 0 auto;
}

.star {
  position: absolute;
  font-size: 30px;
  bottom: 5px;
  right: -5px;
  color: orange;
}
.cloudStar {
  position: absolute;
  font-size: 36px;
  bottom: -14px;
  right: -8px;  
  color: orange;
}
.legenda {
  position: absolute;
  top: 50%;
  left: 37%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px 5px 5px 10px;
  width: 200px;
  height: 200px;
  padding-left: 45px;
  border-radius: 50%;
  font-size: 12px !important;
  font-style: italic;
  font-weight: 400;
}
.stackedBarChartBox {
  position: relative;
  width: 100%;
}
.stackedBarChart {
  padding: 20px 0 10px;
}
.stackedBarLegend,
.stackedBarChart {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.stackedBarLegend {
  justify-content: space-between;
}
.stackedBar {
  display: flex;
  width: 100%;
  height: 50px;
  background: rgba(211, 211, 211, 0.3);
}
.stackedBar .value {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  color: #fff;
  font-size: 9px;
}
.stackedBarLegend > div:nth-child(13) > .value,
.stackedBarLegend > div:nth-child(7) > .value,
.stackedBarLegend > div:nth-child(1) > .value,
.stackedBar .value:nth-child(13),
.stackedBar .value:nth-child(7),
.stackedBar .value:nth-child(1) {
  background: #facf66;
}
.stackedBarLegend > div:nth-child(14) > .value,
.stackedBarLegend > div:nth-child(8) > .value,
.stackedBarLegend > div:nth-child(2) > .value,
.stackedBar .value:nth-child(14),
.stackedBar .value:nth-child(8),
.stackedBar .value:nth-child(2) {
  background: #b1235c;
}
.stackedBarLegend > div:nth-child(15) > .value,
.stackedBarLegend > div:nth-child(9) > .value,
.stackedBarLegend > div:nth-child(3) > .value,
.stackedBar .value:nth-child(15),
.stackedBar .value:nth-child(9),
.stackedBar .value:nth-child(3) {
  background: #3c396b;
}
.stackedBarLegend > div:nth-child(16) > .value,
.stackedBarLegend > div:nth-child(10) > .value,
.stackedBarLegend > div:nth-child(4) > .value,
.stackedBar .value:nth-child(16),
.stackedBar .value:nth-child(10),
.stackedBar .value:nth-child(4) {
  background: #e98434;
}
.stackedBarLegend > div:nth-child(17) > .value,
.stackedBarLegend > div:nth-child(11) > .value,
.stackedBarLegend > div:nth-child(5) > .value,
.stackedBar .value:nth-child(17),
.stackedBar .value:nth-child(11),
.stackedBar .value:nth-child(5) {
  background: #79357e;
}
.stackedBarLegend > div:nth-child(18) > .value,
.stackedBarLegend > div:nth-child(12) > .value,
.stackedBarLegend > div:nth-child(6) > .value,
.stackedBar .value:nth-child(18),
.stackedBar .value:nth-child(12),
.stackedBar .value:nth-child(6) {
  background: #d3342e;
}
.stackedBarLegend .value {
  display: inline-flex;
  width: 20px;
  height: 6px;
  margin-right: 8px;
  margin-left: 8px;
}
.stackedBarLegend .label {
  font-size: 10px;
  color: #555;
  white-space: nowrap;
}
.cloudsChartBox {
  position: relative;
  width: 300px;
  margin: 0 auto;
  margin-top: -50px;
}
.cloudsChart img {
  display: block;
  width: 300px;
  height: 300px;
}
.cloudsChartMarker {
  position: absolute;
  width: 15px;
  height: 21px;
  margin-bottom: 10px;
  margin-left: -8px;
}
.cloudsChartLegend {
  width: 200px;
  margin: 50px auto 0;
  margin-top: 0px;
}
.legendInfo {
  white-space: nowrap;
  line-height: 11px;
}
.cloudsChartLegend .label,
.cloudsChartLegend :global(.label) {
  font-size: 11px !important;
  font-weight: 500;
  color: #444;
  white-space: nowrap;
}
.description {
  font-size: 11px;
  text-align: justify;
}
.mb3 {
  margin-bottom: 14px;
}
.mt2 {
  margin-top: 8px;
}
.mt3 {
  margin-top: 14px;
}
.mt4 {
  margin-top: 18px;
}
.my1 {
  margin-top: 4px;
  margin-bottom: 4px;
}
.my2 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.my3 {
  margin-top: 14px;
  margin-bottom: 14px;
}
.mb1 {
  margin-bottom: 4px;
}
.mb2 {
  margin-bottom: 8px;
}
.me0 {
  margin-right: 0px;
}
.me1 {
  margin-right: 4px;
}
.me2 {
  margin-right: 8px;
}
.me3 {
  margin-right: 14px;
}
.me4 {
  margin-right: 15px;
}
.my0 {
  margin-top: 0;
  margin-bottom: 0;
}
.pt2 {
  padding-top: 4px;
}
.ps2 {
  padding-left: 4px;
}


.dominanceContainer {
  position: relative;
  width: 380px;
  height: 80px;
  margin-right: 10px;
  margin-left: 10px;
}
.RadarChartArea {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
}

.RadarChartBox {
  position: relative;
  align-items: center;
  width: 500px;
  height: 500px;
  margin: auto;
}

.pinkText {
  color: #b1235c;
}
.bold {
  font-weight: bold !important;
}
.blackText {
  color: #3c396b;
}
.uppercase {
  text-transform: uppercase;
}

.background {
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: grid;
}

.frontPageContent {
  position: absolute;
  height: 90%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-bottom: 15px;  
}
.frontPageTitle {
  font-size: 60px;
  text-align: left;
  margin-left: 40px;
  margin-top: 0px;
  margin-bottom: -12px;
}
.frontPageTitle2 {
  font-size: 66px;
  text-align: left;
  margin-left: 40px;
  margin-top: 0px;
  margin-bottom: -12px;
}
.frontPageTitleCenter {
  font-size: 60px;
  width: 100%;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold !important;
  margin-top: 180px;
  margin-bottom: 60px;
}
.frontPageText {
  text-transform: uppercase;
  width: 50%;
}
.floatRight {
  float: left;
  display: block;
  align-items: left;
  justify-content: left;
  margin-left: 20px;
  margin-right: 20px;
  font-size: 42px;
  font-weight: 600 !important;
 }
.floatLeft {
  float: right;
  display: block;
  justify-content: right;
  align-items: right;
  text-align: right;
  margin-left: 20px;
  margin-right: 20px;  
  font-size: 38px;
}
.logoBox {
  height: 200px;
  width: 200px;
  margin-bottom: 80px;
  margin-top: 50px;
  margin-left: 30px;
  padding-bottom: 150px;
  justify-content: top;
  display: block;
  position: relative;
}
.logoBig img {
  object-fit: contain;
}


.descriptionframe {
  border: 2px solid #b1235c;
  border-radius: 5px;
  padding: 8px;
  font-style: italic;
  font-size: 10px;
}

.nanobiotype {
  color: #b1235c;
  font-size: 1.3em; 
}