html {
  overflow-x: hidden;
}

body {
  font-size: 62.5%;
  background: #f9f9f9;
  min-height: 100vh;
  position: relative;
  font-family: "Rubik", sans-serif !important;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4;
  box-sizing: border-box;
}
.iconBtn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  width: 38px;
  min-width: 38px;
  border: 0;
  border-radius: 4px;
  color: #222;
  text-decoration: none;
  font-size: 16px;
  margin-right: 4px;
}
.options {
  padding: 0 20px;
  margin: 5px 0;
}
#fontSize {
  display: inline-flex;
  padding: 4px;
  font-size: 16px;
}
.label {
  font-size: 12px;
  margin-bottom: 4px;
}
.secondaryBtn {
  justify-content: center;
  padding: 6px 10px;
  min-height: 48px;
  min-width: 100px;
  background: transparent;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.linkAsButton.secondaryBtn:hover,
.secondaryBtn:hover {
  background: rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.inputBox {
  display: flex;
  align-items: center;
}
.inputBox input {
  display: inline-flex;
  align-items: center;
  height: 48px;
  width: 100%;
  max-width: 520px;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 12px;
  background: #ddd;
}

.inputBox input:focus {
  border-color: #2196f3;
  box-shadow: none;
  outline: none;
}
#detailsName,
#detailsExpand {
  display: none;
}
