html {
  overflow-x: hidden;
}

[list]::-webkit-calendar-picker-indicator {
  display: block;
  filter: invert(1);
}

body {
  background: #262154;
  min-height: 100vh;
  position: relative;
  font-family: "Rubik", sans-serif !important;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4;
  box-sizing: border-box;
  color: #fff;
  margin: 0;
  text-align: justify;
}

.pageWrapper {
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 150px 1fr;
  gap: 0px 0px;
  color: #fff;
  background: #262154 url(../images/P7FZSQW-2500.png) top center no-repeat;
  background-size: auto;
}
@media (min-width: 640px) {
  .pageWrapper {
    grid-template-rows: 130px 1fr;
  }
}

.dashboardPageWrapper {
  height: auto;
  min-height: 100vh;
}
.appLogo {
  margin: 15px 0;
}
.appLogo img {
  display: block;
  height: 60px;
}
.sygnetSmall {
  display: none;
}
.sygnetBig {
  height: 12px;
  margin-top: 0;
  margin-right: 4px;
}
.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
}
.header h5 {
  margin: 0;
  font-size: 1rem;
}
p {
  text-align: justify;
}
@media (max-width: 639px) {
  .headerRightSide {
    width: 100%;
    justify-content: space-between;
  }
}
@media (min-width: 640px) {
  .pageWrapper {
    grid-template-rows: 130px 1fr;
  }
  .appLogo {
    margin: 0;
  }
  .appLogo img {
    height: 86px;
  }
  .header {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0;
  }
  .header h5 {
    margin: 0;
    font-size: 1.2rem;
  }
  .sygnetSmall {
    display: block;
    height: 21px;
    margin-top: 8px;
    margin-right: 15px;
  }
}
.validationMessage {
  color: #e6007c;
  font-size: 0.8rem;
  display: none;
}
.mainContainer {
  width: 100%;
}
.mainContainer h4 {
  font-size: 1.85rem;
  margin: 0;
}
.formBox {
  min-height: 382px;
  background: #302f67;
  border-radius: 8px;
}
@media (max-width: 420px) {
  .mainContainer {
    max-width: 290px;
  }
  .formBox {
    overflow: auto;
    max-width: 320px;
    margin: 0 auto;
  }
}
@media (min-width: 421px) and (max-width: 479px) {
  .mainContainer {
    max-width: 400px;
  }
}
.inputBox {
  display: block;
}
.inputBox textarea {
  display: flex;
  align-items: center;
  height: 48px;
  width: 100%;
  max-width: 520px;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 12px;
  background: rgba(255, 255, 255, 0.35);
}
textarea:hover {
  border-color: #be38c0;
}
textarea:focus,
.inputBox textarea:focus {
  border-color: #2196f3;
  box-shadow: none;
  outline: none;
}
.input {
  display: flex;
  align-items: center;
  height: 48px;
  width: 100%;
  max-width: 520px;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 12px;
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
}
input[type="text"]:focus,
input[type="number"]:focus,
input[type="date"]:focus,
input[type="time"]:focus,
.input:focus {
  border-color: #2196f3;
  box-shadow: none;
  outline: none;
}
input[type="text"]:hover,
input[type="number"]:hover,
input[type="date"]:hover,
input[type="time"]:hover,
.input:hover {
  border-color: #be38c0;
  box-shadow: none;
  outline: none;
}

.input::placeholder {
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.95rem;
}
.inputBoxSm input {
  max-width: 80px;
}
.inputBox textarea {
  min-height: 100px;
}
.inputBox.invalid textarea,
.inputBox.invalid input {
  border-color: #e6007c;
}
.option.invalid ~ .validationMessage,
.inputBox.invalid ~ .validationMessage {
  display: block;
}
.label {
  font-size: 0.85 !important;
  margin-bottom: 5px !important;
}
@media (min-width: 640px) {
  .label {
    font-size: 1rem !important;
  }
  .probeNumber {
    font-size: 1.85rem;
  }
}
.unit {
  margin-left: 10px;
  font-size: 0.8rem;
  font-weight: 400;
}
::-webkit-input-placeholder {
  color: #fff;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #fff;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
}

:-ms-input-placeholder {
  color: #fff;
}

a {
  color: #fff;
  text-decoration: none;
}
a:hover {
  color: #be38c0;
}

.btnPrimary,
.btnSecondary {
  justify-content: center;
  min-width: 146px;
  display: inline-flex;
  align-items: center;
  min-height: 48px;
  border: 0;
  border-radius: 4px;
  color: #fff;
  text-decoration: none;
  font-size: 0.95rem;
}
.link {
  color: #fff;
  text-decoration: none;
  opacity: 0.9;
}

.link:hover {
  color: #fff;
  opacity: 0.7;
}

.btnPrimary {
  background: #813482;
}

.btnPrimary:hover {
  background: #be38c0;
}

.btnSecondary {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.35);
}

.btnSecondary:hover {
  background: rgba(255, 255, 255, 0.35);
  border: 1px solid rgba(255, 255, 255, 0.35);
  color: #fff;
}

.btnIcon {
  width: 38px;
  min-width: 38px;
  height: 38px;
}

/* Checkbox, radiobutton and switch */

input[type="checkbox"],
input[type="radio"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

.option {
  display: block;
  position: relative;
  padding: 4px 10px 4px 0;
  padding-left: 30px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: 400;
  cursor: pointer;
  font-size: 0.95rem !important;
  user-select: none;
  border-radius: 4px;
}

.option input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: 0;
}

.radiomark,
.checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 20px;
  width: 20px;
  border: 0;
  border-radius: 3px;
  background: #fff;
}

.radiomark {
  border-radius: 50%;
}

.radiomark:after,
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.option input:checked ~ .radiomark,
.option input:checked ~ .checkmark {
  background-color: #bd39c0;
  border: 3px solid #fff;
}

.option:hover input ~ .radiomark,
.option:hover input ~ .checkmark {
  background-color: #be38c0;
}

.option input:checked ~ .radiomark:after,
.option input:checked ~ .checkmark:after {
  display: block;
}

.option--inactive input:checked ~ .radiomark,
.option--inactive input:checked ~ .checkmark,
.option--inactive .radiomark,
.option--inactive .checkmark,
.option--inactive,
.option--inactive:hover,
.option--inactive:focus {
  opacity: 0.5;
  cursor: not-allowed;
  background: transparent;
}
.option.invalid input ~ .radiomark,
.option.invalid input ~ .checkmark {
  border: 1px solid #e6007c;
}
.info {
  margin-top: 10px;
  color: rgba(255, 255, 255, 0.35);
  text-align: center;
}
.ctaBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 340px;
  border: 1px solid rgba(255, 255, 255, 0.35);
  border-radius: 4px;
  font-size: 1.85rem;
  font-weight: 100;
  text-align: center;
}
.ctaBox strong {
  font-weight: 600;
}
.ctaBox a {
  color: #706fb0;
}
.ctaBox a:hover {
  color: #be38c0;
}
.moreInfo {
  display: inline-flex;
  background: #222;
  padding: 0 4px;
  font-weight: 600;
}

.probeNumber {
  font-size: 1.25rem;
}
.addBtn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 180px;
  min-width: 180px;
  height: 180px;
  border: none;
  border-radius: 8px;
  background: #813482;
  color: rgba(255, 255, 255, 0.35);
}
.plus {
  font-size: 11.5rem;
  line-height: 8.5rem;
  font-weight: 700;
}
/*  */
.mainContainer .surveyIntroHeader {
  font-weight: 300;
  font-size: 2.5rem;
}
.surveyIntroHeader strong {
  font-weight: 700;
}
.surveyIntro {
  font-size: 1.3rem;
  font-weight: 300;
}
.surveyBtnBox {
  /* width: auto;
  max-width: 300px; */
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.surveyBtnBox button {
  width: 100%;
}
.section {
  padding: 30px 10px;
  margin: 0 auto;
}
.section:nth-of-type(odd) {
  background: rgb(49 46 120 / 40%);
}
.frameSection.invalid,
.section.invalid {
  background: rgb(129 52 130 / 40%);
}
@media (min-width: 992px) {
  .section {
    margin: 30px auto;
  }
}

.tableRowWithTextarea,
.tableRowWithInput,
.tableRow {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  align-items: center;
  width: 100%;
  padding: 4px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  font-weight: 400;
}

@media (min-width: 992px) {
  .tableRowWithTextarea,
  .tableRowWithInput,
  .tableRow {
    grid-template-columns: 2fr 1fr 1fr 1fr;
  }
  .tableRowWithInput {
    grid-template-columns: 1fr 2fr 1fr 1fr;
  }
  .tableRowWithTextarea {
    grid-template-columns: 3fr 1fr 1fr;
  }
}
.group {
  border-top: 1px solid rgba(255, 255, 255, 0.35);
}
.textGreen {
  color: #54db9d;
}
.textRed {
  color: #e6007c;
}
.cta {
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0 5px 5px;
  color: #262154;
  text-decoration: none;
  border: none;
  outline: none;
  background: transparent;
}
.cta:hover .ctaBackground {
  cursor: pointer;
  background: #eee;
}
.ctaBackground {
  content: "";
  position: absolute;
  top: calc(50% - 80px);
  right: -80px;
  width: 160px;
  height: 160px;
  background: #fff;
  border-radius: 4px;
  transform: rotate(45deg);
}
.cta span {
  color: #fff;
}
.ctaText {
  position: relative;
  z-index: 1;
  width: 90px;
  text-align: center;
  font-size: 0.9rem;
}
.cta:hover .ctaText {
  color: #262154;
}
.sv_main ul {
  padding: 0 !important;
}
@media (max-width: 768px) {
  .sv_row {
    display: flex;
    flex-wrap: wrap !important;
    flex-direction: column;
  }
  .sv_row fieldset {
    display: flex;
    flex-direction: column;
  }
  .sv_q_select_column .sv-q-column-2 {
    max-width: 100% !important;
    width: 100% !important;
  }
}
@media (max-width: 991px) {
  .sv-q-column-5 {
    max-width: 100% !important;
    width: 100% !important;
  }
}
#root .sv_main.sv_frame form .sv_container {
  max-width: 100% !important;
  width: 100% !important;
  padding: 0 !important;
}
div[role="alert"] input[aria-invalid="true"] {
  background: #be38c0 !important;
  border-color: #be38c0 !important;
}
.select-many-button {
  position: absolute;
  top: 24px;
  right: 0px;
}
.errorContainer {
  position: relative;
  min-height: calc(100vh - 200px - 3rem);
  display: flex;
  justify-content: center;
  width: 100%;
}
.message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.message h1 {
  font-size: 6rem;
  color: #ddd;
  font-weight: 900;
}
@media (min-width: 480px) {
  .message h1 {
    font-size: 8rem;
  }
}
.cursor-pointer {
  cursor: pointer;
}
