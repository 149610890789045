.checkbox {
  position: relative;
  display: flex;
}
.input {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  border: 1px solid transparent;
}
.icon {
  fill: none;
  stroke: white;
  stroke-width: 2.5px;
  visibility: hidden;
}
.styledCheckbox {
  display: inline-block;
  width: 21px;
  height: 21px;
  min-width: 21px;
  min-height: 21px;

  border-radius: 3px;
  transition: all 100ms;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input:checked + .styledCheckbox {
  background-color: #bd39c0;
  border: 3px solid #fff;
}
.input:hover + .styledCheckbox {
  background-color: #bd39c0;
  border: 1px solid transparent;
}

.input:checked + .styledCheckbox .icon {
  visibility: visible;
}
.label {
  font-size: 16px;
  margin-left: 8px;
  margin: 0 0 0 10px;
}
