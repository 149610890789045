#root,
.root {
  position: relative;
  width: calc(100vw - 2rem);
  font-family: "Rubik", sans-serif !important;
  font-size: 14px;
  background-color: transparent;
  color: #fff;
}
@media (min-width: 768px) {
  #root,
  .root {
    width: calc(100vw - 3rem);
  }
}
.root :global(.sv_custom_header) {
  display: none;
}
.textWhite {
  color: #fff !important;
}
.bgTransparent {
  background-color: transparent !important;
}

.input[name*="select-many"] {
  margin-top: -20px;
}

.input {
  overflow: visible !important;
}
.input[name*="select-many"] > div > div > fieldset > table > tbody > tr > td > span {
  font-weight: 900;
  text-transform: uppercase;
}

.input input:not([type="checkbox"]):not([type="radio"]):not([type="file"]) {
  height: 48px !important;
  width: 100% !important;
  max-width: 520px !important;
  border: 1px solid transparent !important;
  border-radius: 4px !important;
  padding: 12px !important;
  background: rgba(255, 255, 255, 0.3) !important;
  color: #fff !important;
}

.input input:not([type="checkbox"]):not([type="radio"]):not([type="file"]):focus {
  border-color: #2196f3 !important;
  box-shadow: none;
  outline: none;
}
.input input:not([type="checkbox"]):not([type="radio"]):not([type="file"]):hover {
  border-color: #be38c0 !important;
  box-shadow: none;
  outline: none;
}

.input textarea {
  height: 100px !important;
  min-height: 100px !important;
  width: 100% !important;
  max-width: 520px !important;
  border: 1px solid transparent !important;
  border-radius: 4px !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-bottom: 12px !important;
  padding-top: 4px !important;
  background: rgba(255, 255, 255, 0.35) !important;
  color: #fff !important;
}
.input textarea:hover {
  border-color: #be38c0 !important;
  box-shadow: none;
  outline: none;
}
.input textarea:focus {
  border-color: #2196f3 !important;
  box-shadow: none;
  outline: none;
}

.fileInput {
  height: 60px !important;
  width: 220px !important;

  max-width: 220px !important;
  border: 1px solid transparent !important;
  border-radius: 4px !important;
  padding: 12px !important;
  background: rgba(255, 255, 255, 0.3) !important;
  color: #fff !important;
  margin-right: 20px;
}

.input textarea:focus {
  border-color: #2196f3;
  box-shadow: none;
  outline: none;
}

.input input:not([type="checkbox"]):not([type="radio"]):focus {
  border-color: #2196f3;
  box-shadow: none;
  outline: none;
}

.input input:not([type="checkbox"]):not([type="radio"])::placeholder {
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.95rem;
}

.validationMessage {
  color: #e6007c;
  font-size: 0.8rem;
}
.invalid.input {
  border-radius: 4px;
  background: rgb(233 30 99 / 15%) !important;
  border: 1px solid #e6007c !important;
}
.invalid input,
.invalid textarea {
  border: 1px solid #e6007c !important;
}
.input input:not([type="checkbox"]):not([type="radio"]):not([type="file"])[aria-invalid="true"] {
  background: rgb(233 30 99 / 75%) !important;
  border-color: #be38c0 !important;
}
.stepDescription {
  display: block;
  font-size: 0.5rem;
  text-align: center;
  white-space: break-spaces;
  font-weight: 400;
}

.step {
  width: calc(14.29% - 20px);
  margin-right: 20px;
  max-width: 200px;
  opacity: 0.3;
}
.stepActive {
  opacity: 1 !important;
}
.stepNumber {
  position: relative;
  cursor: pointer;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2rem;
}
.stepNumber,
.step span {
  text-align: center;
}
.stepper ul {
  padding-left: 0;
}
.stepper li {
  width: calc(14.29% - 20px);
  max-width: 200px;
  margin-right: 20px;
  margin-bottom: 50px;
  opacity: 0.3;
}
.stepNumber:after {
  content: "";
  position: absolute;
  width: 20px;
  height: 30px;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  background: url(../../assets/images/arrow.png) right center no-repeat;
}
.step:last-of-type .stepNumber:after {
  display: none;
}

.stepper li:last-of-type .stepNumber:after {
  display: none;
}
@media (min-width: 768px) {
  .step {
    width: calc(14.29% - 40px);
    margin-right: 40px;
  }
  .stepNumber {
    position: relative;
    font-size: 4rem;
    line-height: 4rem;
  }
  .stepNumber:after {
    width: 40px;
  }
  .stepDescription {
    font-size: 0.7rem;
    font-weight: 600;
  }
  .stepNumber:after {
    content: "";
    position: absolute;
    width: 40px;
    height: 30px;
    left: 90%;
    top: 50%;
    transform: translateY(-50%);
    background: url(../../assets/images/arrow.png) right center no-repeat;
  }
}
@media (min-width: 992px) {
  .step {
    width: calc(14.29% - 50px);
    margin-right: 50px;
  }
  .stepNumber {
    position: relative;
    font-size: 6rem;
    line-height: 6rem;
  }
  .step span {
    display: block;
  }
  .stepNumber:after {
    width: 50px;
  }
  .stepDescription {
    display: block;
    font-size: 0.9rem;
    text-align: center;
    white-space: break-spaces;
    font-weight: 900;
  }
  .stepNumber:after {
    content: "";
    position: absolute;
    width: 50px;
    height: 30px;
    left: 90%;
    top: 50%;
    transform: translateY(-50%);
    background: url(../../assets/images/arrow.png) right center no-repeat;
  }
}

.radiomark,
.checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 20px;
  width: 20px;
  border: 0;
  border-radius: 3px;
  background: #fff;
}

.radiomark {
  border-radius: 50%;
}

.radiomark:after,
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.option {
  line-height: 1.5;
  display: flex;
  align-items: center;
  position: relative;
  padding: 4px 10px 4px 0;
  padding-left: 30px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: 400;
  cursor: pointer;
  font-size: 0.95rem;
  user-select: none;
  border-radius: 4px;
}
.option input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: 0;
}
.option input:checked ~ .radiomark,
.option input:checked ~ .checkmark {
  background-color: #bd39c0;
  border: 3px solid #fff;
}

.option:hover input ~ .radiomark,
.option:hover input ~ .checkmark {
  background-color: #be38c0;
  border: 3px solid #be38c0;
}

.option:focus input ~ .radiomark,
.option:focus input ~ .checkmark {
  background-color: #be38c0 !important;
  border: 3px solid rgba(255, 255, 255, 0.5) !important;
}

.option input:checked ~ .radiomark:after,
.option input:checked ~ .checkmark:after {
  display: block;
}

.option--inactive input:checked ~ .radiomark,
.option--inactive input:checked ~ .checkmark,
.option--inactive .radiomark,
.option--inactive .checkmark,
.option--inactive,
.option--inactive:hover,
.option--inactive:focus {
  opacity: 0.5;
  cursor: not-allowed;
  background: transparent;
}
.option.invalid input ~ .radiomark,
.option.invalid input ~ .checkmark {
  border: 1px solid #e6007c;
}
.dropdownContainer :global(.sv_select_wrapper) {
  background-color: transparent;
  max-width: 520px !important;
}
.dropdownContainer :global(.sv_select_wrapper)::before {
  background-color: #813482 !important;
  width: 48px !important;
  height: 48px !important;
  padding: 0 !important;
  top: 0 !important;
  border-radius: 0 4px 4px 0 !important;
}

.dropdown {
  line-height: 1.5 !important;
  height: 48px !important;
  width: 100% !important;
  max-width: 520px !important;
  border: 1px solid transparent !important;
  border-radius: 4px !important;
  padding: 12px !important;
  background: rgba(255, 255, 255, 0.3) !important;
  color: #fff !important;
}

:global(.sv_q_rating_item_text:hover) {
  border: 1px solid #be38c0 !important;
}

:global(.sv_main .sv_q_rating_item.active .sv_q_rating_item_text) {
  background-color: #813482;
  border-color: #813482;
  color: white;
}
:global(.sv_main .sv_q_imgsel.checked label > div) {
  background-color: #be38c0;
}
.switch {
  background-color: #be38c0 !important;
}

.rankingIcon {
  fill: #be38c0 !important;
}
.rankingContent {
  display: flex;
  align-items: center;
}
.rankingIndex {
  background-color: #813482 !important;
  font-size: 1.1rem;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rankingViewer {
  font-size: 0.95rem;
}
:global(.sv_main input[type="button"]) {
  color: white;
  background: #813482 !important;
}
:global(.sv_main button) {
  color: white;
  background: #813482 !important;
}

:global(.sv_main input[type="button"]) {
  background: #be38c0 !important;
}

:global(.sv_main button:hover) {
  background: #be38c0 !important;
}
.matrixSelected {
  background: #813482 !important;
}
.matrixCell {
  width: 400px;
}

.matrixLabel input:checked {
  background-color: #813482;
}

.chooseFileBtn {
  border: 1px solid #813482 !important;
}
.removeFile:hover {
  cursor: pointer;
  color: #be38c0 !important;
}

.frame {
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  padding-top: 30px;
  margin-top: 50px;
  margin-bottom: 15px;
}
.frameLabel {
  position: absolute;
  top: -18px;
  left: 10px;
  margin-right: 10px;
  background: #f9e49d;
  padding: 8px;
  border-radius: 4px;
  color: #222;
  font-size: 0.8rem;
}
.progressBar {
  background-color: #813482 !important;
}

.matrixOption {
  line-height: 1.5;
  display: flex;
  align-items: center;
  position: relative;
  padding: 4px 10px 4px 0;
  padding-left: 30px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: 400;
  cursor: pointer;
  font-size: 0.95rem;
  user-select: none;
  border-radius: 4px;
}
.matrixOption span {
  position: absolute;
  top: 7px;
  left: 50%;
  transform: translateX(-50%);
  height: 20px;
  width: 20px;
  border: 0;
  border-radius: 3px;
  background: #fff;
  border-radius: 50%;
}

.matrixOption input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: 0;
}

.matrixOption span:after {
  content: "";
  position: absolute;
  display: none;
}

.matrixOption input:checked ~ span {
  background-color: #bd39c0;
  border: 3px solid #fff;
}

.matrixOption:hover input ~ span {
  background-color: #be38c0;
  border: 3px solid #be38c0;
}
.matrixOption:focus input ~ span {
  background-color: #be38c0 !important;
  border: 3px solid rgba(255, 255, 255, 0.5) !important;
}

.matrixOption input:checked ~ span:after {
  display: block;
}
@media (max-width: 768px) {
  .matrixOption span {
    left: 15px;
    transform: translateX(0);
  }
  .frameLabel {
    position: relative;
  }
  :global(.select-many-button) {
    position: relative;
    top: 0;
    left: 0;
  }
  form {
    margin-left: -20px;
  }
}
