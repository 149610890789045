.navBox {
  min-height: 400px;
  background: #302f67;
  border-radius: 4px 0 0 4px;
}

.navLink {
  display: block;
  padding: 4px 0;
  color: rgba(255, 255, 255, 0.35);
  text-decoration: none;
  cursor: pointer;
}

.navLink:hover {
  color: #be38c0;
}

.activeLink {
  color: #fff;
}
.activeLink:hover {
  color: #fff;
}

.reportBox {
  min-height: calc(100vh - 100px - 3rem);
  background: #f3f3f3;
  color: #222;
  border-radius: 0 4px 4px 4px;
  padding-top: 2rem;
  padding-bottom: 3rem;
  overflow: visible;
}
.reportBox h5 {
  font-size: 1.4rem;
  padding: 0 0.75rem;
}
.reportBox h6 {
  text-transform: uppercase;
}
.hintBox {
  position: relative;
  display: flex;
  justify-content: flex-end;
}
.hint {
  background: #222;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  min-width: 28px;
  height: 28px;
  border-radius: 50%;
  color: #fff;
  font-size: 1.3rem;
  font-weight: 600;
}
.hintTooltip {
  position: absolute;
  top: 30px;
  right: 0;
  display: none;
  width: 300px;
  background: #f9e49d;
  border-radius: 4px;
  padding: 10px;
  color: #222;
  font-size: 12px;
  z-index: 10;
}
.hint:hover {
  cursor: pointer;
}
.hint:hover ~ .hintTooltip {
  display: block;
}
.hintWrapper {
  position: absolute;
  top: 161px;
  width: 100%;
  background: linear-gradient(90deg, #f3f3f3 7%, transparent 22%, transparent, transparent);
  padding: 3px;
}
.hintText {
  position: absolute;
  width: 100%;
  background: #f9e49d;
  border-radius: 4px;
  padding: 5px 10px 10px;
  margin-top: 4px;
  color: #222;
  font-size: 13px;
  z-index: 10;
}
.closeBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background: transparent;
  border-radius: 50%;
  color: #333;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.closeBtn:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.15);
}
  .closeBtn.infoBoxBtn {
    color: #f3f3f3;
    border: 1px solid #f3f3f3;
  }
  .closeBtn.infoBoxBtn:hover {
    background: rgba(0, 0, 0, 0.35);
  }
.textGreen {
  color: #54db9d;
}
.textRed {
  color: #e6007c;
}
.textBox {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  text-align: justify;
}
@media (max-width: 991px) {
  .barChartBox,
  .scaleChartBox {
    margin: 0 auto;
  }
  .scaleChartBox {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .barChartBox,
  .scaleChartBox {
    margin: 0 auto;
    width: 65%;
  }
}
.textBoxLg,
.chartBoxLg {
  width: 100%;
}
.textBox,
.chart {
  padding: 10px 0;
  background: transparent;
  border-radius: 4px;
}
.chart {
  height: 250px;
}
.textBox h3 {
  font-weight: 300;
  font-size: 1.4rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
}
.intro {
  font-weight: 600;
}
.enterotype {
  font-size: 2rem;
  font-weight: 800;
  text-transform: uppercase;
  margin-top: 3rem;
}
.infographic img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}
.resultLabel {
  white-space: nowrap;
  padding-top: 4px;
}
.result {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 10px;
  text-align: center;
  white-space: nowrap;
}
.resultbmi {
  font-weight: 400;
  font-size: 1rem;
  text-align: center;
  white-space: nowrap;
  float: right;
}
.species {
  font-weight: 400;
  font-size: 1rem; 
  text-align: center;
  white-space: nowrap;
  border: 3px solid #e98434;
  color: #252351;
  padding: 6px;
  width: 120px;
  }
.speciesdesc {
  font-weight: 400;
  font-size: 0.7rem;
  text-align: center;
  white-space: nowrap;
  float: right;
  border: 3px solid #e98434;
  color: #252351;
  padding: 6px;
  font-size: 16px;
}
.speciesdiv {
  float: right;
  display: flex;
  margin-left: 50px;
  margin-top: 20px;
}
.result strong {
  font-size: 32px;
  font-weight: 400;
}
.species strong {
  font-size: 28px;
  font-weight: 600;
}

.halfDonutChartBox {
  position: relative;
  width: 360px;
  margin: 0 auto;
  overflow: hidden;
}
.halfDonutChart {
  margin: 0 auto;
  padding: 0;
  list-style-type: none;
}

.halfDonutChart *,
.halfDonutChart::before {
  box-sizing: border-box;
}

/* halfDonutChart */

.halfDonutChart {
  position: relative;
  width: 300px;
  height: 150px;
  margin-top: 26px;
}

.halfDonutChart::before,
.halfDonutChart::after {
  position: absolute;
}

.halfDonutChart::before {
  content: "";
  width: inherit;
  height: inherit;
  border: 15px solid rgba(211, 211, 211, 0.3);
  border-bottom: none;
  border-top-left-radius: 150px;
  border-top-right-radius: 150px;
}

.halfDonutChart div {
  position: absolute;
  top: 100%;
  left: 0;
  width: inherit;
  height: inherit;
  border: 15px solid;
  border-top: none;
  border-bottom-left-radius: 150px;
  border-bottom-right-radius: 150px;
  transform-origin: 50% 0;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  animation-fill-mode: forwards;
  /* animation-duration: 0.4s; */
  animation-timing-function: linear;
}

.halfDonutChart div:nth-child(1) {
  z-index: 5;
  border-color: #c7c7c7;
}

.halfDonutChart div:nth-child(2) {
  z-index: 4;
  border-color: #e98433;
  /* animation-delay: 0.4s; */
}

.halfDonutChart div:nth-child(3) {
  z-index: 3;
  border-color: #8bc34a;
  /* animation-delay: 0.8s; */
}

.halfDonutChart div:nth-child(4) {
  z-index: 2;
  border-color: #e98433;
  /* animation-delay: 1.2s; */
}
.halfDonutChart div:nth-child(5) {
  z-index: 1;
  border-color: #c7c7c7;
  /* animation-delay: 1.2s; */
}

.halfDonutChart span {
  position: absolute;
  font-size: 12px;
  font-weight: 600;
  backface-visibility: hidden;
  /* animation: fade-in 0.4s linear forwards; */
}

.halfDonutChart div:nth-child(2) span {
  top: -8px;
  left: 10px;
  transform: rotate(-5deg);
  /* animation-delay: 0.4s; */
}

.halfDonutChart div:nth-child(4) span {
  top: -6px;
  left: 10px;
  transform: rotate(-175deg);
  /* animation-delay: 1.2s; */
}
.chartLabelsContainer {
  width: 350px;
  margin: 0 auto;
  padding-bottom: 30px;
}

.chartLabel {
  font-size: 16px;
  width: 60px;
  font-weight: 400;
  text-align: center;
}
.halfDonutChartLegend {
  position: absolute;
  left: 50%;
  bottom: -30px;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.halfDonutChartLegend strong,
.halfDonutChartLegend span {
  font-weight: bold;
  color: #252351;
  text-align: center;
}

.halfDonutChartLegend span {
  font-size: 10px;
}

.halfDonutChartLegend strong {
  font-size: 38px;
  font-weight: 500;
}

.halfDonutChartBox .marker {
  animation-name: rotate-six;
  z-index: 6;
  background: transparent;
  border: transparent;
  top: 100%;
}

.chartArea {
  position: relative;
  width: 360px;
  margin: 0 auto;
}

.halfDonutChartBox .donutmarkerImg {
  position: absolute;
  top: -10px;
  left: -18px;
  width: 15px;
  height: 21px;
  transform: rotate(-90deg);
}
@media (max-width: 480px) {
  .mainContainer {
    width: 100%;
    max-width: 290px;
  }
  .chartArea {
    width: 240px;
  }
  .halfDonutChartBox {
    position: relative;
    width: 240px;
    margin: 0 auto;
    overflow: hidden;
  }

  /* halfDonutChart */

  .halfDonutChart {
    position: relative;
    width: 240px;
    height: 120px;
    margin-top: 26px;
  }
  .chartLabelsContainer {
    width: 300px;
  }
}

.pieChartContainer {
  position: relative;
  padding: 20px 0;
}

.pieChart {
  border-radius: 50%;
  width: 300px;
  height: 300px;
  margin: 0 auto;
}

.legenda {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f3f3f3;
  padding: 5px 5px 5px 40px;
  width: 270px;
  height: 270px;
  padding-left: 45px;
  border-radius: 50%;
  font-size: 11px;
  font-style: italic;
  font-weight: 400;
}
@media (max-width: 480px) {
  .pieChart {
    border-radius: 50%;
    width: 240px;
    height: 240px;
    margin: 0 auto;
  }
  .legenda {
    padding: 5px 5px 5px 30px;
    width: 200px;
    height: 200px;
    padding-left: 35px;
    font-size: 9px !important;
  }
}
.entry {
  display: flex;
  align-items: center;
  margin: 2px 0;
}

.entryColor {
  height: 8px;
  width: 8px;
  border-radius: 8px;
}

.entryText {
  margin-left: 5px;
}

.red {
  background-color: #d3342e;
}

.orange {
  background-color: #e98434;
}

.yellow {
  background-color: #facf66;
}

.pink {
  background-color: #b1235c;
}

.black {
  background-color: #3c396b;
}

.purple {
  background-color: #79357e;
}
.scaleChartBox,
.barChartBox {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.barChartBox {
  position: relative;
  padding-top: 4px;
}
.barChartBar {
  position: relative;
  margin: 0 auto;
}
.barChartLabelsBox {
  width: calc(200px + 60px);
  margin: 0 -30px;
  position: relative;
}
.barChartLabel {
  width: 30px;
}
.barChartLabelLeft {
  text-align: right;
}
.barChartLabelRight {
  text-align: left;
}
.barChartMarker {
  position: absolute;
  bottom: -18px;
  display: block;
  height: 19px;
  width: 13px;
}
.barChartBar,
.wave {
  width: 200px;
  height: 30px;
}
@media (min-width: 481px) {
  .barChartBox,
  .barChartBar,
  .wave {
    max-width: 380px;
    width: 380px;
    height: auto;
  }
  .barChartLabelsBox {
    width: calc(380px + 60px);
  }
}
@media (min-width: 576px) {
  .barChartBox,
  .barChartBar,
  .wave {
    max-width: 460px;
    width: 460px;
    height: auto;
  }
  .barChartLabelsBox {
    width: calc(460px + 60px);
  }
}
@media (min-width: 768px) {
  .barChartBox,
  .barChartBar,
  .wave {
    max-width: 460px;
    width: 460px;
    height: auto;
  }
  .barChartLabelsBox {
    width: calc(460px + 60px);
  }
}
@media (min-width: 992px) {
  .barChartBox,
  .barChartBar,
  .wave {
    max-width: 620px;
    width: 620px;
    height: auto;
  }
  .barChartLabelsBox {
    width: calc(620px + 60px);
  }
}
@media (min-width: 1200px) {
  .barChartBox,
  .barChartBar,
  .wave {
    max-width: 760px;
    width: 760px;
    height: auto;
  }
  .barChartLabelsBox {
    width: calc(760px + 60px);
  }
}
@media (min-width: 1400px) {
  .barChartBox,
  .barChartBar,
  .wave {
    max-width: 900px;
    width: 900px;
    height: auto;
  }
  .barChartLabelsBox {
    width: calc(900px + 60px);
  }
}

.barChartLabel {
  font-weight: 500;
  margin-top: 2px;
}

.star {
  position: absolute;
  font-size: 40px;
  bottom: 7px;
  right: -5px;
  color: orange;
}

.cloudStar {
  position: absolute;
  font-size: 40px;
  bottom: -24px;
  right: -10px;  
  color: orange;
}

.scaleChartBox {
  height: 46px;
}
.scaleChartMarker {
  position: absolute;
  top: -4px;
  display: block;
  height: 19px;
  width: 13px;
}
.scaleChartBar {
  position: relative;
  width: 100%;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 10px;
  background: linear-gradient(to right, #009688, #ffeb3b, #f44336);
}
.gradientActivity {
  background: linear-gradient(to left, #009688 40%, #ffeb3b, #f44336);
}
.gradientBMI {
  background: linear-gradient(to right, #ffeb3b, #009688, #ffeb3b, #f44336);
}
.gradientAlcohol {
  background: linear-gradient(90deg, #009688, #ffeb3b, #ffe03b, #f44336, #f44336, #f44336);
}
.gradientUnhealthy {
  background: linear-gradient(90deg, #009688, #ffeb3b, #f44336 50% );
}
.gradientRedGreen {
  background: linear-gradient(90deg, #f44336, #ffeb3b, #009688 );
}

.scaleChartLabel {
  font-size: 12px;
  font-weight: 500;
  margin-top: 4px;
}
.divider {
  width: 2px;
  height: 10px;
  background: #fff;
}
.iconLabel {
  font-size: 8px !important;
  text-align: center;
  width: 120px;
  max-width: 120px;
  padding-top: 4px;
}
@media print {
  .infographic img {
    display: block;
    margin: 0 auto;
    width: 300px;
  }
  .pieChart {
    border-radius: 50%;
    width: 220px;
    height: 220px;
    margin: 0 auto;
  }

  .pieChartContainer {
    position: relative;
    padding: 10px 20px;
    background-color: #fff;
  }

  .legenda {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    padding: 5px 5px 5px 10px;
    width: 200px;
    height: 200px;
    padding-left: 45px;
    border-radius: 50%;
    font-size: 11px !important;
    font-style: italic;
    font-weight: 400;
  }
}
.stackedBarChartBox {
  position: relative;
  width: 100%;
}
.stackedBarChart {
  padding: 20px 0 10px;
}
.stackedBarLegend,
.stackedBarChart {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.stackedBarLegend {
  justify-content: space-between;
}
.stackedBar {
  display: flex;
  width: 100%;
  height: 60px;
  background: rgba(211, 211, 211, 0.3);
}
.stackedBar .value {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  color: #fff;
  font-size: 9px;
}
.stackedBarLegend > div:nth-child(13) > .value,
.stackedBarLegend > div:nth-child(7) > .value,
.stackedBarLegend > div:nth-child(1) > .value,
.stackedBar .value:nth-child(13),
.stackedBar .value:nth-child(7),
.stackedBar .value:nth-child(1) {
  background: #facf66;
}
.stackedBarLegend > div:nth-child(14) > .value,
.stackedBarLegend > div:nth-child(8) > .value,
.stackedBarLegend > div:nth-child(2) > .value,
.stackedBar .value:nth-child(14),
.stackedBar .value:nth-child(8),
.stackedBar .value:nth-child(2) {
  background: #b1235c;
}
.stackedBarLegend > div:nth-child(15) > .value,
.stackedBarLegend > div:nth-child(9) > .value,
.stackedBarLegend > div:nth-child(3) > .value,
.stackedBar .value:nth-child(15),
.stackedBar .value:nth-child(9),
.stackedBar .value:nth-child(3) {
  background: #3c396b;
}
.stackedBarLegend > div:nth-child(16) > .value,
.stackedBarLegend > div:nth-child(10) > .value,
.stackedBarLegend > div:nth-child(4) > .value,
.stackedBar .value:nth-child(16),
.stackedBar .value:nth-child(10),
.stackedBar .value:nth-child(4) {
  background: #e98434;
}
.stackedBarLegend > div:nth-child(17) > .value,
.stackedBarLegend > div:nth-child(11) > .value,
.stackedBarLegend > div:nth-child(5) > .value,
.stackedBar .value:nth-child(17),
.stackedBar .value:nth-child(11),
.stackedBar .value:nth-child(5) {
  background: #79357e;
}
.stackedBarLegend > div:nth-child(18) > .value,
.stackedBarLegend > div:nth-child(12) > .value,
.stackedBarLegend > div:nth-child(6) > .value,
.stackedBar .value:nth-child(18),
.stackedBar .value:nth-child(12),
.stackedBar .value:nth-child(6) {
  background: #d3342e;
}
.stackedBarLegend .value {
  display: inline-flex;
  width: 20px;
  height: 6px;
  margin-right: 8px;
}
.stackedBarLegend .label {
  font-size: 9px;
  color: #555;
  white-space: nowrap;
}
.cloudsChartBox {
  position: relative;
  width: 300px;
  margin: 0 auto;
}
.cloudsChart img {
  display: block;
  width: 300px;
  height: 300px;
}
.cloudsChartMarker {
  position: absolute;
  width: 15px;
  height: 21px;
  margin-bottom: 10px;
  margin-left: -8px;
}
.cloudsChartLegend {
  width: 200px;
  margin: 10px auto 0;
}
.legendInfo {
  white-space: nowrap;
  line-height: 11px;
}
.cloudsChartLegend .label {
  font-size: 9px;
  font-weight: 500;
  color: #444;
  white-space: nowrap;
}
.description {
  font-size: 11px;
  text-align: justify;
  list-style-type: disc;
}
:global(.pageHeader h1) {
  font-size: 18px;
}
:global(.footer) {
  position: absolute;
  bottom: -0.8cm;
  width: 780px;
  font-size: 0.9rem;
}
:global(.footer .stripe) {
  height: 0.45cm;
  height: 0.45cm;
  min-height: 0.45cm !important;
  background: #252351;
  margin-top: 4px;
}
:global(.footerText) {
  padding: 10px 0.5cm 0 0.5cm;
}
.groupTitle {
  font-weight: 600;
}

.plotTitle {
  font-style: italic;
}


.dominanceContainer {
  position: relative;
  width: 400px;
  height: 100px;
  margin-left: 50px;

  margin-right: 20px;
  margin-left: 50px;
}
.RadarChartArea {
  position: relative;
  width: 100%;
  align-items: center;
}

.RadarChartBox {
  position: relative;
  align-items: center;
  width: 550px;
  height: 500px;
  margin: 0px auto auto auto;
}
.descriptionframe {
  border: 2px solid #b1235c;
  border-radius: 5px;
  padding: 8px;
  font-style: italic;
  font-size: 0.8em;
}
.nanobiotype {
  color: #b1235c;
  font-size: 1.3em; 
}