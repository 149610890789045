/* @import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=block");*/
/* @import "@fontsource/open-sans"; */
/* @import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); */




@font-face {
  font-family: "Lato";
  src: url(../../assets/fonts/Lato/Lato-Regular.ttf)
    format("truetype");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
      U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: normal;
  src: url(../../assets/fonts/Lato/Lato-Italic.ttf)
    format("truetype");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
      U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
    font-family: "Lato";
    font-weight: bold;
    src: url(../../assets/fonts/Lato/Lato-Bold.ttf)
      format("truetype");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
        U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Lato";
    font-weight: bolder;
    src: url(../../assets/fonts/Lato/Lato-Black.ttf)
      format("truetype");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
        U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Lato";
  font-weight: normal;
  src: url(../../assets/fonts/Lato/Lato-Regular.ttf)
    format("truetype");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
      U+2C60-2C7F, U+A720-A7FF;
}

/* latin-ext */

.singlePage {
  position: relative;
  height: calc(8.7in * 1.33);
  max-height: calc(8.7in * 1.33);
  min-height: calc(8.7in * 1.33);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  break-after: always !important;
  break-inside: avoid !important;
  color: #323e4f  ;
  font-family: "Lato",  "Open-Sans", sans-serif !important;
  line-height: 1.1em;
  padding: 0.1cm 0.4cm;  
  font-weight: normal; 
}
.pageHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: -5px;
}
.content {
  padding: 10px 0.5cm 0.5cm 0.5cm;
}
.title {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  color: #323e4f;
  text-transform: uppercase;
  word-spacing: 6px;
}
.titleCenter {
  margin: 0;
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.5em;
  color: #323e4f;
  /* text-transform: uppercase; */
  word-spacing: 6px;
  text-align: center;
}
h5 {
  font-size: 15px;
  margin-bottom: 2px;
}
h5 ~ div em {
  font-size: 17px;
}
.barcode img {
  display: block;
  height: 20px;
}


.resultLabel {
  font-weight: bold;
  font-size: 14px;
  margin-top: 5px;
  text-align: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  color: #1e1c4d;
}
.result {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 20px;
  text-align: center;
  white-space: nowrap;
  color: #1e1c4d;
  text-align: center;
  flex-wrap: nowrap;
  margin-top: 10px;
  margin-bottom: 10px;
}
.result strong {
  font-size: 26px;
  font-weight: bold;
}

.boxTitle {
  font-size: 13px;
  text-align: left;
  font-weight: bold;
  color: #323e4f;    
  margin-top: 2px;

  /* text-transform: uppercase; */
  /* word-spacing: 6px; */
}

.moveright {
  overflow: auto;  
}

.scaleChartBox {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 48px;
}
.textBox .barChartMarker {
  position: absolute;
  top: 2px;
  display: block;
  height: 13px;
  width: 10px;
}
.smallTitle,
.subTitle {
  font-size: 14px;
}
.boldTitle {
  font-weight: bold !important;
  font-size: 15px;
}
.boldText {
  font-weight: bold !important;
  font-size: 12px;
}
.italicText {
  font-style: italic;
}
.smallTitle {
  font-weight: bold !important;
}
.underlinedTitle {
  text-decoration: underline;
}

.pieChartContainer {
  position: relative;
  height: 180px;
  width: 560px;
  margin-top: -5px; 
  margin-bottom: -5px; 
  margin-left: -40px; 
  margin-right: -40px; 
}

.scaleChartBox,
.barChartBox {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.barChartBox {
  position: relative;
  padding-top: 4px;
}
.barChartBox {
  margin: 0 auto;
  width: 100%;
}
.barChartBar {
  position: relative;
  width: 100%;
  height: 20px;
  margin: 0 auto;
}
.tableBarChartBar {
  position: relative;
  height: 10px;
  margin-top: 10px;
}
.barChartLabelsBox {
  width: 100%;
  margin: 0px;
}
.barChartLabel {
  margin-left: -20px;
  position: relative;
  font-size: 10px;
  font-weight: bold;
  text-align: right;
  margin-top: 0px;
}
.barChartLabelLeft {
  text-align: right;
}
.barChartLabelRight {
  text-align: left;
}
.barChartLabelDiv {
  text-align: center;
  width: 40px;
}
.wave {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
}
.lineBox {
  margin: 5px 20px;
}
.downLabel {
  position: absolute;
  margin-left: -130px;
  margin-top: 10px;
  font-size: 9px;
}

.barChartMarker {
  position: absolute;
  display: inline-block;
  height: 14px;
  margin-top: 3px;
}
.barChartMarkerSmall {
  position: absolute;
  display: inline-block;
  height: 8px;
  margin-top: 1px;
  margin-left: -4px;
}
.divider {
  width: 2px;
  height: 20px;
  position: absolute;
  display: inline-block;
  background: #009ed6;
}

.description {
  font-size: 12px;
  text-align: justify;
  font-weight: normal; 
  line-height: normal;
}
.smallDescription {
  font-size: 10px;
  text-align: justify;
  font-weight: normal; 
  line-height: normal;
}
.mt1 {
  margin-top: 4px;
}
.mt2 {
  margin-top: 8px;
}
.mt3 {
  margin-top: 14px;
}
.mt4 {
  margin-top: 18px;
}
.my1 {
  margin-top: 4px;
  margin-bottom: 4px;
}
.my2 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.my3 {
  margin-top: 14px;
  margin-bottom: 14px;
}
.mx3 {
  margin-left: 14px;
  margin-right: 14px;
}
.mx4 {
  margin-left: 20px;
  margin-right: 20px;
}
.mb1 {
  margin-bottom: 4px;
}
.mb2 {
  margin-bottom: 8px;
}
.mb3 {
  margin-bottom: 14px;
}
.mb3 {
  margin-bottom: 20px;
}
.me0 {
  margin-right: 0px;
}
.me1 {
  margin-right: 4px;
}
.me2 {
  margin-right: 8px;
}
.me3 {
  margin-right: 14px;
}
.me4 {
  margin-right: 15px;
}
.ml2 {
  margin-left: 10px;
}
.ml4 {
  margin-left: 15px;
}
.my0 {
  margin-top: 0;
  margin-bottom: 0;
}
.pt2 {
  padding-top: 4px;
}
.ps2 {
  padding-left: 4px;
}
.pl4 {
  padding-left: 30px;
}

.box {
  border: 1px solid #009ed6;
  color: #323e4f;
  margin: 10px;
  padding: 10px 10px 0px 10px;
  width: 98%;
}


.tablerowfirst {
  border-left: 0.5px solid #9291A9;
}
.tablerow {
  border-bottom: 0.5px solid #9291A9;
  border-right: 0.5px solid #9291A9;
  color: #323e4f;
  margin: 0;
  padding: 0px;
  height: 40px;
}
.tabletext {
  font-size: 11px;
  padding: 12px 12px 10px 12px;
  text-align: left;
  font-weight: normal;
}
.tableresult {
  text-align: center;
  font-size: 13px;
  padding: 12px 12px 10px 12px;
  font-weight: bold;
}
.tableLabel {
  font-size: 10px;
  padding: 20px 5px 5px 5px; 
  font-weight: normal;
}
.tableheader {
  border-right: 0.5px solid #9291A9;
  border-top: 0.5px solid #9291A9;
  border-bottom: 0.5px solid #9291A9;
  background-color: #323e4f;
  color: #ffffff;
  margin: 0;
  padding: 4px;
  text-align: center;
  font-size: 10px;
}

.tableheaderfirst {
  border-left: 0.5px solid #9291A9;
}
.outlier {
  color: #1e1c4d;
  font-weight: bold !important;
  font-size: 11px;
  margin-bottom: 0;
}


.tableWave {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
}

.reportBox {
  min-height: calc(100vh - 100px - 3rem);
  background: #f3f3f3;
  color: #222;
  border-radius: 0 4px 4px 4px;
  padding-top: 2rem;
  padding-bottom: 3rem;
  overflow: visible;
}