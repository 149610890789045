.closeBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  min-width: 34px;
  height: 34px;
  padding: 0;
  position: absolute;
  top: 5px;
  right: 5px;
  outline: none;
  border-radius: 50%;
}

.closeBtn:hover {
  background: hsla(0, 0%, 0%, 0.06);
}

.closeBtn:focus {
  outline: 0;
  box-shadow: none;
}

.closeBtn:hover img {
  transform: scale(1.15);
}

.fullScreen {
  margin: 10px;
  width: calc(100vw - 20px);
  height: calc(100vh - 20px);
}
