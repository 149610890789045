.container {
  position: relative;
  height: 34px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dateInput {
  display: block;
  width: 100%;
  border: 1px solid transparent;
  height: 48px;
  border-radius: 4px;
  align-items: center;
  background: transparent;
  cursor: pointer;
  -webkit-appearance: listbox;
  outline: none;
  position: relative;
  padding: 12px;
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
}

.dateInput:focus {
  border: 1px solid #40a4ff;
  outline: none;
}

.dateInputWrapper {
  width: 100%;
  display: block;
}

.calendarIcon {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-56%);
  right: 8px;
  filter: contrast(0.5) brightness(1.95);
}
.disabledIcon {
  cursor: not-allowed;
}
.closeIcon {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-56%);
  right: 8px;
}

.popper {
  width: 281px;
  z-index: 6;
}
.commonLabel {
  position: absolute;
  top: -10px;
  left: 4px;
  font-size: 12px;
  color: #808080;
  padding: 2px;
  display: inline-flex;
  background: #fff;
  z-index: 1;
}
.commonInput {
  border: 1px solid #ddd;
}
.commonInput:focus {
  box-shadow: #765ee3 0px 0px 0px 0;
  outline: none;
}
.caret {
  transform: rotate(90deg) translate(-56%);
  width: 10px;
  object-fit: contain;
  position: absolute;
  top: 50%;
  right: 8px;
}
